/** 企业、专利、商标描述列表 */
<template>
  <div v-if="data">
    <h2 v-if="title" class="details-main-title mb-10">{{title}}</h2>
    <el-descriptions :column="column" border>
      <el-descriptions-item v-for="(item,index) in labelData" :key="index" :label="item.label" :span="item.span||1">
        <slot v-if="item.isSlot" :name="item.value" :item="item"></slot>
        <template v-else-if="data[item.value] instanceof Array">
          <span v-for="(i,ind) in data[item.value]" :key="ind" :class="{'is-br':!item.isNotBr}">
            {{item.value2?i[item.value2]:i}}
            {{item.isNotBr && setCount(ind,data[item.value].length-1)?',':''}}
          </span>
        </template>
        <template v-else>
          {{data[item.value]}}
        </template>
        <span v-if="item.text" class="flot-right">
          {{item.text}}
        </span>
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    data: {
      typeof: Object,
      default: {}
    },
    labelData: Array,
    column: {
      typeof: Number,
      default: 2
    }
  },
  methods: {
    setCount (num1, num2) {
      return num1 < num2
    }
  },
}
</script>
<style lang="scss" scoped>
.details-main-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}
.is-br {
  display: block;
}
.flot-right {
  float: right;
  color: #8490ad;
}
</style>