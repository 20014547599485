var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"trademark-details"},[(_vm.tradeDetailsData)?_c('section',{staticClass:"back-fff mb-10"},[_c('div',{staticClass:"content flex ptb-32"},[_c('el-image',{staticClass:"details-img border-r6 mr-22",attrs:{"src":_vm.info.tradeMark.tmsg,"fit":"contain"}}),_c('div',{staticClass:"details-info flex-1 flex-column justify-between"},[_c('div',{staticClass:"justify-between"},[_c('div',{staticClass:"details-name"},[_c('h4',{staticClass:"mb-10"},[_vm._v(_vm._s(_vm.info.tradeMark.mno))]),_c('el-tag',{staticClass:"mr-8",attrs:{"effect":"dark","color":"#6AA2FF","size":"small "}},[_vm._v(_vm._s(_vm.info.tradeMark.ts))]),_c('el-tag',{staticClass:"mr-8",attrs:{"effect":"dark","color":"#6AA2FF","size":"small "}},[_vm._v(_vm._s(_vm.info.tradeMark.wkm))])],1),_c('div',{staticClass:"details-btns"},[_c('el-button',{staticClass:"monitor-btn",attrs:{"loading":_vm.monitor_load},on:{"click":function($event){return _vm.monitorBtn(_vm.info.tradeMark.tid)}}},[_vm._v(" "+_vm._s(!_vm.$store.getters.userToken || !_vm.info.tradeMark.associate_trademark_status ? "监控" : "取消监控"))]),_c('el-button',{staticClass:"report-download theme-back ml-20",on:{"click":function($event){return _vm.$store.dispatch('setDownloadDialog', true)}}},[_vm._v(" 报告下载 ")])],1)]),_c('el-row',{attrs:{"gutter":10}},[_c('el-col',{attrs:{"span":8}},[_vm._v("申请号/注册号："+_vm._s(_vm.info.tradeMark.rn))]),_c('el-col',{attrs:{"span":8}},[_vm._v("申请日："+_vm._s(_vm.info.tradeMark.fd))]),_c('el-col',{attrs:{"span":8}},[_vm._v("国际分类："+_vm._s(_vm.info.tradeMark.nc_cn))]),_c('el-col',{attrs:{"span":8}},[_vm._v("申请人："+_vm._s(_vm.info.applicants[0].hno))]),_c('el-col',{attrs:{"span":8}},[_c('router-link',{attrs:{"to":{
                path: '/enterprise-details',
                query: { qn: _vm.info.tradeMark.aro },
              }}},[_vm._v(" 代理机构： "+_vm._s(_vm.info.tradeMark.aro)+" ")])],1),_c('el-col',{attrs:{"span":8}},[_vm._v("初审公告期号："+_vm._s(_vm.info.tradeMark.peai))])],1)],1)],1)]):_vm._e(),(_vm.tradeDetailsData)?_c('section',{staticClass:"back-fff pb-140"},[_c('div',{staticClass:"content"},[_c('el-tabs',{ref:"detailsTabsRef",class:{ isFixed: _vm.isFixed },attrs:{"type":"card"},on:{"tab-click":_vm.handleToggle},model:{value:(_vm.detailsName),callback:function ($$v) {_vm.detailsName=$$v},expression:"detailsName"}},_vm._l((_vm.detailsTabs),function(item,index){return _c('el-tab-pane',{key:index,attrs:{"label":item.label,"name":item.name}})}),1),(_vm.isFixed)?_c('div',{staticStyle:{"height":"99px"}}):_vm._e(),_c('ul',{ref:"panelRef",staticClass:"details-main"},[_c('li',{staticClass:"details-main-item"},[_c('AssetsDescriptions',{attrs:{"title":"基本信息","data":_vm.dataOne.data,"labelData":_vm.dataOne.label},scopedSlots:_vm._u([{key:"reg",fn:function(ref){
              var item = ref.item;
return _vm._l((_vm.dataOne.data[item.value]),function(i,ind){return _c('p',{key:ind},[_vm._v(" "+_vm._s(i[item.value3])+"    "+_vm._s(i[item.value2])+" ")])})}}],null,false,1227008476)})],1),_c('li',{staticClass:"details-main-item"},[_c('AssetsDescriptions',{attrs:{"title":"续展信息","data":_vm.dataTwo.data,"labelData":_vm.dataTwo.label}})],1),_c('li',{staticClass:"details-main-item"},[_c('AssetsDescriptions',{attrs:{"title":"商标公告","data":_vm.dataThree.data,"labelData":_vm.dataThree.label}})],1),_c('li',{staticClass:"details-main-item"},[_c('AssetsTable',{attrs:{"title":"商标质押","data":_vm.dataFour.data,"labelData":_vm.dataFour.label,"total":_vm.dataFour.pageTotal,"pageSize":10,"isMaxTotal":true},on:{"changePage":_vm.changePage},scopedSlots:_vm._u([{key:"nc_cn",fn:function(ref){
              var scope = ref.scope;
return [_vm._v(" "+_vm._s(_vm.info.tradeMark.nc_cn)+" ")]}},{key:"myPledgePeople",fn:function(ref){
              var scope = ref.scope;
return [_c('p',[_vm._v("出质人："+_vm._s(scope.row.opdo))]),_c('p',[_vm._v("质权人："+_vm._s(scope.row.pdpo))])]}},{key:"sred",fn:function(ref){
              var scope = ref.scope;
return [_vm._v(" "+_vm._s(_vm.info.tradeMark.sred)+" ")]}}],null,false,2260862999)})],1),_c('li',{staticClass:"details-main-item"},[_c('AssetsDescriptions',{attrs:{"title":"商品服务","data":_vm.dataFive.data,"labelData":_vm.dataFive.label}})],1)])],1)]):_vm._e(),(_vm.tradeDetailsData)?_c('AssetsDownload',{attrs:{"downloadType":"trademark","downloadVal":_vm.info.tradeMark.tid}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }