/** 商标详情 */
<template>
  <div class="trademark-details">
    <section v-if="tradeDetailsData" class="back-fff mb-10">
      <div class="content flex ptb-32">
        <el-image class="details-img border-r6 mr-22" :src="info.tradeMark.tmsg" fit="contain">
        </el-image>
        <div class="details-info flex-1 flex-column justify-between">
          <div class="justify-between">
            <div class="details-name">
              <h4 class="mb-10">{{ info.tradeMark.mno }}</h4>
              <el-tag class="mr-8" effect="dark" color="#6AA2FF" size="small ">{{ info.tradeMark.ts }}</el-tag>
              <el-tag class="mr-8" effect="dark" color="#6AA2FF" size="small ">{{ info.tradeMark.wkm }}</el-tag>
            </div>
            <div class="details-btns">
              <el-button class="monitor-btn" :loading="monitor_load" @click="monitorBtn(info.tradeMark.tid)">
                {{
                  !$store.getters.userToken ||
                  !info.tradeMark.associate_trademark_status
                    ? "监控"
                    : "取消监控"
                }}</el-button>
              <el-button class="report-download theme-back ml-20" @click="$store.dispatch('setDownloadDialog', true)">
                报告下载
              </el-button>
            </div>
          </div>
          <el-row :gutter="10">
            <el-col :span="8">申请号/注册号：{{ info.tradeMark.rn }}</el-col>
            <el-col :span="8">申请日：{{ info.tradeMark.fd }}</el-col>
            <el-col :span="8">国际分类：{{ info.tradeMark.nc_cn }}</el-col>
            <el-col :span="8">申请人：{{ info.applicants[0].hno }}</el-col>
            <el-col :span="8">
              <router-link :to="{
                  path: '/enterprise-details',
                  query: { qn: info.tradeMark.aro },
                }">
                代理机构： {{ info.tradeMark.aro }}
              </router-link>
            </el-col>
            <el-col :span="8">初审公告期号：{{ info.tradeMark.peai }}</el-col>
          </el-row>
        </div>
      </div>
    </section>
    <section v-if="tradeDetailsData" class="back-fff pb-140">
      <div class="content">
        <el-tabs ref="detailsTabsRef" v-model="detailsName" type="card" @tab-click="handleToggle"
          :class="{ isFixed: isFixed }">
          <el-tab-pane v-for="(item, index) in detailsTabs" :key="index" :label="item.label" :name="item.name">
          </el-tab-pane>
        </el-tabs>
        <div v-if="isFixed" style="height: 99px"></div>
        <ul ref="panelRef" class="details-main">
          <li class="details-main-item">
            <AssetsDescriptions title="基本信息" :data="dataOne.data" :labelData="dataOne.label">
              <template v-slot:reg="{ item }">
                <p v-for="(i, ind) in dataOne.data[item.value]" :key="ind">
                  {{ i[item.value3] }} &nbsp;&nbsp; {{ i[item.value2] }}
                </p>
              </template>
            </AssetsDescriptions>
          </li>
          <li class="details-main-item">
            <AssetsDescriptions title="续展信息" :data="dataTwo.data" :labelData="dataTwo.label" />
          </li>
          <li class="details-main-item">
            <AssetsDescriptions title="商标公告" :data="dataThree.data" :labelData="dataThree.label" />
          </li>
          <li class="details-main-item">
            <AssetsTable title="商标质押" :data="dataFour.data" :labelData="dataFour.label" :total="dataFour.pageTotal"
              :pageSize="10" :isMaxTotal="true" @changePage="changePage">
              <template v-slot:nc_cn="{ scope }">
                {{ info.tradeMark.nc_cn }}
              </template>
              <template v-slot:myPledgePeople="{ scope }">
                <p>出质人：{{ scope.row.opdo }}</p>
                <p>质权人：{{ scope.row.pdpo }}</p>
              </template>
              <template v-slot:sred="{ scope }">
                {{ info.tradeMark.sred }}
              </template>
            </AssetsTable>
          </li>
          <li class="details-main-item">
            <AssetsDescriptions title="商品服务" :data="dataFive.data" :labelData="dataFive.label" />
          </li>
        </ul>
      </div>
    </section>
    <!-- 下载框 -->
    <AssetsDownload v-if="tradeDetailsData" downloadType="trademark" :downloadVal="info.tradeMark.tid" />
  </div>
</template>
<script>
import AssetsDescriptions from "@/components/Assets/descriptions.vue";
import AssetsTable from "@/components/Assets/table.vue";
import AssetsDownload from "@/components/Assets/download.vue";
import {
  getTradeDetails,
  getTradePledge,
  setTradeMonitor,
} from "@/api/trademark";
export default {
  name: "TrademarkDetails",
  components: {
    AssetsDescriptions,
    AssetsTable,
    AssetsDownload,
  },
  data () {
    return {
      tradeDetailsData: null,
      group: {},
      info: {},
      reg: [],
      monitor_load: false, // 监控按钮加载状态
      isFixed: false, // tabs吸顶
      detailsTabs: [
        { label: "基础信息", name: "tabs1" },
        { label: "续展信息", name: "tabs2" },
        // { label: '商标公告', name: 'tabs3' },
        { label: "商标质押", name: "tabs4" },
        { label: "商品服务", name: "tabs5" },
      ],
      detailsName: "tabs1",
      dataOne: {
        label: [
          { label: "商标名称", value: "mno", span: "2" },
          { label: "申请/注册号", value: "rn" },
          { label: "国际分类", value: "nc_cn" },
          { label: "申请日期", value: "fd" },
          { label: "商标法律状态", value: "cs" },
          { label: "申请人", value: "hno" },
          { label: "商标类型", value: "mk" },
          { label: "初审日期", value: "fad" },
          { label: "初审公告期号", value: "peai" },
          { label: "注册日期", value: "rd" },
          { label: "截止日期", value: "sred" },
          { label: "代理机构", value: "aro" },
          { label: "申请人地址", value: "hnado", span: "2" },
          {
            label: "商标注册流程",
            value: "reg",
            isSlot: true,
            value2: "mpde",
            value3: "mpd",
          },
        ],
        data: null,
      },
      dataTwo: {
        label: [
          { label: "到期时间", value: "end_date", span: "2" },
          {
            label: "建议缴费时间",
            value: "advice_date",
            text: "（建议提前1年或3个月，进行商标续展）",
            span: "2",
          },
          {
            label: "最晚缴费",
            value: "latest_date",
            text: "（宽展期6个月，再此时间内缴纳费用不影响商标使用）",
            span: "2",
          },
        ],
        data: null,
      },
      dataThree: {
        label: [{ label: "商标公告", value: "info" }],
        data: null,
      },
      dataFour: {
        label: [
          { label: "商标名称", value: "mno" },
          { label: "注册号", value: "rn" },
          { label: "国际分类", value: "nc_cn", isSlot: true },
          {
            label: "出质人/质权人",
            value: "myPledgePeople",
            align: "left",
            isSlot: true,
            width: "400px",
          },
          { label: "质押开始日期", value: "pdgd" },
          { label: "质押结束日期", value: "sred", isSlot: true },
        ],
        data: null,
        pageTotal: 0,
      },
      dataFive: {
        label: [
          {
            label: "类似群组",
            value: "cnServiceListsNum",
            value2: "ncs",
            isNotBr: true,
            span: "2",
          },
          {
            label: "商品项",
            value: "cnServiceListsCon",
            value2: "gsc",
            isNotBr: true,
            span: "2",
          },
        ],
        data: null,
      },
    };
  },
  mounted () {
    this.$store.dispatch('setQueryType', 'trademark')
    this.getTradeDetails();
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    // 获取商标详情数据
    async getTradeDetails () {
      try {
        let { data, status } = await getTradeDetails(this.$route.query.tid);
        if (status == 200) {
          this.tradeDetailsData = data;
          this.group = data.group;
          this.info = data.info;
          this.reg = data.reg;
          this.dataOne.data = {
            ...this.info.tradeMark,
            ...this.info.applicants[0],
            ...this.info.kinds[0],
            reg: this.reg,
          };
          this.dataTwo.data = { ...this.info.tradeMark };

          this.dataFive.data = {
            cnServiceListsNum: this.$utils.unique(this.info.cnServiceLists, "ncs"),
            cnServiceListsCon: this.info.cnServiceLists,
          };
          this.getTradePledge();
        }
      } catch (error) {
        return false;
      }
    },
    // 获取商标详情质押数据
    async getTradePledge (page = 1) {
      try {
        let { data, status } = await getTradePledge(this.info.sn, page);
        if (status == 200) {
          this.dataFour.data = data.data;
          this.dataFour.pageTotal = data.total *= 1;
        }
      } catch (error) {
        return false;
      }
    },
    // 商标监控
    async monitorBtn (tid) {
      if (!this.$store.getters.userToken)
        return this.$message.warning("请先登录");
      this.monitor_load = true;
      try {
        let { data, status, msg } = await setTradeMonitor({keywords:tid,monitor_status:this.info.tradeMark.associate_trademark_status?2:1});
        if (status == 200) {
          this.info.tradeMark.associate_trademark_status =
            !this.info.tradeMark.associate_trademark_status;
          this.$message.success(
            this.info.tradeMark.associate_trademark_status
              ? "监控成功"
              : "取消监控成功"
          );
        } else {
          this.$message.error(
            this.info.tradeMark.associate_trademark_status
              ? "取消监控失败"
              : "监控失败"
          );
        }
        this.monitor_load = false;
      } catch (error) {
        this.monitor_load = false;
      }
    },
    // 分页切换
    changePage (page) {
      this.getTradePledge(page);
    },
    handleToggle ({ index }) {
      window.scrollTo(
        0,
        this.$refs.panelRef.getElementsByTagName("li")[index].offsetTop - 180
      );
    },
    handleScroll () {
      this.isFixed = window.pageYOffset >= 214;
    },
  },
  destroyed () {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
};
</script>
<style lang="scss" scoped>
.details-img {
  width: 130px;
  height: 140px;
}
.details-info {
  .details-name {
    width: 76%;
  }
  .el-col {
    font-size: 16px;
    color: #1a1a1a;
    margin: 5px 0;
  }
}

.isFixed {
  width: 100%;
  position: fixed;
  top: 80px;
  background-color: #fff;
  z-index: 88;
}
</style>