/** 企业、专利、商标下载 */
<template>
  <el-dialog title="报告下载" :visible.sync="downloadDialog" :lock-scroll="false" width="812px" center>
    <div class="download-select">

      <div class="download-item mb-20 border-r4" v-for="(item,index) in downloadList[downloadType]" :key="index"
        @click="downloadBtn(item.type)">
        <div class="justify-between-center mb-10">
          <h4 class="download-title mt-10">{{item.title}}</h4>
          <el-image class="download-icon" :src="require('@/assets/images/download-icon.png')"></el-image>
        </div>
        <span class="download-text">{{item.text}}</span>
      </div>
      <!-- 企业调查报告下载 -->
      <div v-if="downloadType=='company'" class="download-item mb-20 border-r4">
        <div class="justify-between-center mb-10">
          <h4 class="mt-10">企业调查报告</h4>
        </div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" :inline="true" class="demo-form-inline">
          <el-form-item label="专利可质押筛选："></el-form-item>
          <el-form-item prop="patent_type">
            <el-select v-model="ruleForm.patent_type" multiple collapse-tags clearable placeholder="专利类型">
              <el-option v-for="item in patentType" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="years">
            <el-select v-model="ruleForm.years" clearable placeholder="专利剩余年限">
              <el-option v-for="item in residueYear" :key="item.value" :label="'≥'+item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="download-btn theme-back" @click="submitForm('ruleForm')">报告下载
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { getReportDownload } from '@/api/personal'
export default {
  props: ['downloadType', 'downloadVal'],
  data () {
    return {
      ruleForm: {
        patent_type: '',
        years: '',
      },
      rules: {
        patent_type: [
          { required: true, message: '请选择专利类型', trigger: 'blur' },
        ],
        years: [
          { required: true, message: '请选择剩余年限', trigger: 'blur' }
        ]
      },
      patentType: [{
        value: '发明',
        label: '发明专利'
      }, {
        value: '实用新型',
        label: '实用新型'
      }, {
        value: '外观设计',
        label: '外观设计'
      }],
      residueYear: [
        { value: 8, label: 1 },
        { value: 9, label: 2 },
        { value: 10, label: 3 },
        { value: 11, label: 4 },
        { value: 12, label: 5 },
      ],
      downloadList: {
        company: [
          { type: 'company_survey', title: '企业报告', text: '快速了解企业信息情况，以报告形式呈现企业知识产权详细信息，方便您快速的查看，分析该企业具体情况等操作' }
        ],
        patent: [
          { type: 'patent_survey', title: '专利调查报告', text: '快速了解专利信息情况，以报告形式呈现专利详细信息，方便您快速的查看，分析专利具体情况等操作' },
          { type: 'patent_eval', title: '专利评价报告', text: '在线实时生成的单专利评价报告、企业整体专利评价报告；区域知识产权分析咨询报告、特定技术领域专利分析评价报告定制化的知识产权战略规划、专利布局、专利导航、专利预警、专利侵权分析服务' }
        ],
        trademark: [
          { type: 'trademark_survey', title: '商标调查报告', text: '快速了解商标信息情况，以报告形式呈现商标详细信息，方便您快速的查看，分析商标具体情况等操作' },
        ]
      }

    }
  },
  computed: {
    downloadDialog: {
      get () {
        return this.$store.getters.downloadDialog;
      },
      set (val) {
        this.$store.dispatch('setDownloadDialog', val);
      },
    },
  },
  methods: {
    async downloadBtn (type) {
      let dataObj = {
        search: this.downloadVal,
        type
      }
      if (type == 'company_best') {
        if (this.ruleForm.patent_type.length) {
          dataObj['patent_type'] = this.ruleForm.patent_type
        }
        if (this.ruleForm.years) {
          dataObj['years'] = this.ruleForm.years
        }
      }
      try {
        let { data, status, msg } = await getReportDownload(dataObj)
        if (status == 200 || status == 201) {
          this.$message.success(msg)
          this.$store.dispatch('setDownloadDialog', false);
          this.$router.push('/personal/report')
        } else {
          this.$message.error('生成失败，请稍后重试')
        }
      } catch (error) {
        return false
      }
    },

    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.downloadBtn('company_best')
        } else {
          return false;
        }
      });
    },
  }
}
</script>
<style lang="scss" scoped>
.download-select {
  .download-item {
    cursor: pointer;
    padding: 10px 20px 20px;
    border: 1px solid #ccc;
    h4 {
      font-size: 18px;
    }
    span {
      font-size: 16px;
      line-height: 18px;
    }
    .download-btn {
      float: right;
    }
  }
}
::v-deep .el-form {
  .el-form-item {
    margin-bottom: 0;
  }
}
</style>